import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import Card from '../../components/common/Card';
import EventCalendar from '../../components/EventCalendar';
import EventList from '../../components/EventList';
import SectionHeader from '../../components/SectionHeader';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import { Event } from '../../components/Event';
import ContentContainer from '../../components/common/ContentContainer';
import Img from 'gatsby-image';
import SEO from '../../components/Seo';
import Button from '../../components/common/Button';
import TabToggle from './../../components/common/TabToggle';
import { getEventSections, getGrades } from '../../components/members/api';
import { FormField, FormWrapper } from '../../components/common/Form';
import { useFormik } from 'formik';
import { transformGradeString } from '../../util/grades';
import { getEventSectionType } from '../../helpers/eventSections.helper';

export enum EventTypes {
  INSTITUTE = 'Institute',
  ONE_DAY_CONFERENCE = 'One-Day Conference',
  SEMINAR_SERIES = 'Seminar Series'
}

const Events: React.FC = () => {
  const [selectedView, setSelectedView] = useState('list');
  const [totalEvents, setTotalEvents] = useState(0);
  const [eventSections, setEventSections] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [formattedEvents, setFormattedEvents] = useState<any[]>([]);
  const locationOptions = [
    { label: 'All', value: 'all' },
    { label: 'Remote', value: 'remote' },
    { label: 'In-Person', value: 'in-person' }
  ];

  const data = useStaticQuery(graphql`
    {
      aboutEvents: file(relativePath: { eq: "images/events-1.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      allContentfulEvent(
        sort: { fields: startDate, order: ASC }
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            title
            type
            startDate
            grades
            location
            endDate
            description {
              description
            }
            slug
            price
          }
        }
      }
    }
  `);

  useEffect(() => {
    getEventSections().then(result => {
      setEventSections(result.eventSections ?? []);
    });
    getGrades().then(result => {
      setGradeOptions(
        result.grades?.map((g: any) => ({
          label: g.name,
          value: g.name
        })) || []
      );
    });
  }, []);

  const getEventSectionSetting = (section: any) => {
    const location = section?.location?.name?.toLowerCase();
    return location?.includes('via zoom') || location?.includes('remote')
      ? 'remote'
      : 'in-person';
  };

  const getEventType = (data: any, isContentful = false) => {
    if (isContentful) {
      switch (data.type) {
        case 'Saturday Reunion':
          return EventTypes.SEMINAR_SERIES;
        case 'Institute':
          return EventTypes.INSTITUTE;
        case 'One-Day Conference':
          return EventTypes.ONE_DAY_CONFERENCE;
        default:
          '';
      }
    } else {
      const eventType = data?.group?.eventPath?.event?.eventType?.name;
      switch (eventType) {
        case 'Series':
        case 'Seminar':
          return EventTypes.SEMINAR_SERIES;
        case 'Institute':
          return EventTypes.INSTITUTE;
        case 'Conference Day':
          return EventTypes.ONE_DAY_CONFERENCE;
        default:
          '';
      }
    }
  };

  const getAllEvents = () => {
    return [
      ...data.allContentfulEvent.edges
        ?.map((event: any) => event.node)
        ?.map((event: Event) => {
          return {
            ...event,
            type: 'EVENT',
            setting:
              event?.location?.toLowerCase().includes('via zoom') ||
              event?.location?.toLowerCase().includes('remote')
                ? 'remote'
                : 'in-person',
            eventType: getEventType(event, true),
            grades: transformGradeString(event.grades),
            startDate: new Date(event.startDate),
            endDate: new Date(event.endDate)
          };
        }),
      ...eventSections?.map((section: any) => ({
        type: getEventSectionType(section),
        id: section.id,
        setting: getEventSectionSetting(section),
        eventType: getEventType(section),
        grades: section.audienceType,
        startDate: section.dates[0] ? new Date(section.dates[0]) : '',
        endDate: section.dates[0] ? new Date(section.dates[0]) : '',
        title: section.displayTitle || section.title
      }))
    ];
  };

  useEffect(() => {
    // @ts-ignore
    setFormattedEvents(getAllEvents());
    setTotalEvents(getAllEvents().length);
  }, [data, eventSections]);

  const formik = useFormik<any>({
    initialValues: {
      search: '',
      grades: [],
      location: locationOptions[0].value,
      institute: false,
      oneDayConference: false,
      seminarSeries: false
    },
    onSubmit: () => {}
  });

  useEffect(() => {
    let events = getAllEvents();
    const {
      search,
      grades,
      location,
      institute,
      oneDayConference,
      seminarSeries
    } = formik.values;
    if (search) {
      events = events.filter(event =>
        event.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (grades?.length) {
      events = events.filter(event =>
        grades.some((g: string) => event.grades.includes(g))
      );
    }
    if (location !== 'all') {
      events = events.filter(event => event.setting === location);
    }
    // if one of the event types is selected, filter by that
    if (institute || oneDayConference || seminarSeries) {
      const eventTypesToInclude = [
        (institute && EventTypes.INSTITUTE) || '',
        (oneDayConference && EventTypes.ONE_DAY_CONFERENCE) || '',
        (seminarSeries && EventTypes.SEMINAR_SERIES) || ''
      ];
      events = events.filter(event =>
        eventTypesToInclude.includes(event.eventType)
      );
    }

    setFormattedEvents(events);
  }, [formik.values]);

  const isFilterApplied = () => {
    return (
      formik.values.search ||
      formik.values.grades?.length ||
      formik.values.location !== 'all'
    );
  };
  return (
    <Layout>
      <SEO title="Events" />
      <ContentContainer>
        <div className="flex items-center justify-center w-full mt-6 text-center">
          <SectionHeader header="About Our Events" />
        </div>
        <div className="mt-6 text-center">
          <h3 className="text-xl font-bold text-gray-500">
            Explore Professional Development Opportunities
          </h3>
          <p className="mx-auto mt-4 text-gray-700 sm:w-4/5">
            We host events virtually and in person across the world. Join us to
            study methods and plan curricula, revitalize our thinking, and most
            importantly, encourage our students to lead meaningful and active
            literate lives. Events feature celebrated authors, world-renowned
            teacher educators, and other leaders in the field of literacy and
            learning.
          </p>
        </div>

        {isFilterApplied() && (
          <div className="mt-4 sans-serif">
            Showing {formattedEvents?.length} of {totalEvents} results{' '}
            {formik.values.search && `for "${formik.values.search}"`}
          </div>
        )}
        <div className="flex mt-10">
          <div className="w-full">
            <div className="flex mb-4">
              <TabToggle
                text="List"
                selected={selectedView === 'list'}
                onClick={() => setSelectedView('list')}
              />
              <TabToggle
                text="Full Calendar"
                selected={selectedView === 'full-calendar'}
                onClick={() => setSelectedView('full-calendar')}
              />
            </div>
            <Card>
              {formattedEvents.length ? (
                selectedView === 'full-calendar' ? (
                  <EventCalendar events={formattedEvents} />
                ) : (
                  <EventList events={formattedEvents} />
                )
              ) : (
                <div className="text-center text-gray-500">No events found</div>
              )}
            </Card>
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Events;
