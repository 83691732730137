import React from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Event } from './Event';
import { formatToTimeZone } from 'date-fns-timezone';
import { navigate } from 'gatsby';
import { navigateToEventSectionDetails } from '../helpers/eventSections.helper';

const locales = {
  'en-US': require('date-fns/locale/en-US')
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

interface EventCalendarProps {
  events: Event[];
}

const EventCalendar = (props: EventCalendarProps) => {
  const eventsWithEST = props.events.map((event: Event) => {
    return {
      ...event,
      startDate: formatToTimeZone(event.startDate, '', {
        timeZone: 'America/New_York'
      }),
      endDate: formatToTimeZone(event.endDate, '', {
        timeZone: 'America/New_York'
      })
    };
  });

  return (
    <>
      <Calendar
        localizer={localizer}
        events={eventsWithEST}
        startAccessor="startDate"
        endAccessor="endDate"
        style={{ height: 600 }}
        onSelectEvent={e => navigateToEventSectionDetails(e)}
      />
    </>
  );
};

export default EventCalendar;
